import { useParams } from "react-router-dom";
import { createContext } from "./createContext";

// export const getChains = () =>
//   fetch("https://assets.terra.money/chains.json")
//     .then(res => res.json())
//     .then((data: Record<string, ChainOption>) => Object.values(data));

// TODO: Hardcode for now?
const data = {
  mainnet: {
    name: "mainnet",
    chainID: "columbus-5",
    lcd: "https://lcd.terra.dev",
    mantle: "https://mantle.terra.dev",
    walletconnectID: 1
  },
  testnet: {
    name: "rebirth-1",
    chainID: "rebirth-1",
    // lcd: "http://localhost:1317", // LCD saying "Not Implemented", so we use FCD @ port 3060
    lcd: "https://fcd.terrae.cash",
    // lcd: "http://fcd.terrae.cash:3060",
    mantle: "https://mantle.terrae.cash"
  },
  LocalTerra: {
    name: "localterra",
    chainID: "localterra",
    lcd: "http://localhost:3060",
    mantle: "http://localhost:1337"
  }
};
export const getChains = () =>
  fetch("https://assets.terra.money/chains.json") // TODO: update?
    .then(res => res.json())
    .then(res => data) // overwrite JSON data
    .then((data: Record<string, ChainOption>) => Object.values(data));

export const [useChains, ChainsProvider] =
  createContext<ChainOption[]>("Chains");

const useNetworkFromRouteMatch = () => {
  const { network } = useParams();
  return network;
};

export const useCurrentChain = () => {
  const chains = useChains();
  const network = useNetworkFromRouteMatch();

  const chain =
    chains.find(chain => chain.name === network || chain.chainID === network) ??
    chains.find(chain => chain.name === "mainnet"); // return mainnet for default chain

  if (!chain) {
    throw new Error("Chain is not defined");
  }

  return chain;
};

export const useFCDURL = () => {
  const { lcd } = useCurrentChain();
  return lcd.replace("lcd", "fcd");
};
